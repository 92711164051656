import React, { useState } from "react";
import { Wrapper, Text, CloseButton } from "./notification-bar.styled";
import packageJson from "../../../package.json";

const NotificationBar = (props) => {
  const notification = localStorage.getItem(
    `@${packageJson.name}-notification`
  );

  const [show, setShow] = useState(!notification);

  const onClose = () => {
    localStorage.setItem(`@${packageJson.name}-notification`, true);
    setShow(false);
  };

  return (
    show && (
      <Wrapper>
        <div className="container fixed">
          <Text>{props.children}</Text>
        </div>
        <CloseButton onClick={onClose}>
          <i className="bi bi-x"></i>
        </CloseButton>
      </Wrapper>
    )
  );
};

export default NotificationBar;
