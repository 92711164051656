import 'assets/scss/index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import { Loader, NotificationBar } from './components';
import * as serviceWorker from './serviceWorker';
import store from './store';
import { getEnv } from './utils';
import { Helmet } from "react-helmet";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store} >
      <Suspense fallback={<Loader visible={true} />}>
        <NotificationBar>
          Atenção: Se você está em busca do portal Santillana Español,{" "}
          <a href="https://www.santillanaespanol.com.br">clique aqui.</a>
        </NotificationBar>
        {
          getEnv() === 'hml' && window.location.search.indexOf('type=print') < 0 ? <Helmet>
            <script type="text/javascript" id="PrivallyApp" src="https://app.privally.global/app.js" pid="38bb06-f2bc9a" async></script>
          </Helmet>
            : null
        }
        <Helmet>
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css"></link>
        </Helmet>
        <App />
      </Suspense>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
