import styled from 'styled-components';

export const ContentLoaderWrapper = styled.div`
    .loadercontainer {
        height: calc(100vh - 14px);
        width: 100%;
        position: relative;

        &__loader {
            left: calc(50% - 16px);
            margin: 0;
            position: absolute;
            top: calc(50% - 32px);
            z-index: 1000;
        }

        &__background {
            position: fixed;
            background-color: rgba(256, 256, 256, 0.5);
            height: 100vh;
            width: 100vw;
            left: 0;
            position: fixed;
            top: 0;
            z-index: 999;
        }
    }
`;