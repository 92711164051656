import React from 'react';
import { useEffect, useState } from 'react';
import { api } from 'utils';
import { axiosConfig } from 'config';

import { LogoWrapper } from './logo.styled';

const Logo = props => {
    const [logoSrcDark, setLogoSrcDark] = useState("");
    const [logoSrcLight /*, setlogoSrcLigth*/] = useState("logo-santillana-branco.png");
    const [onetime, setOneTime] = useState(false);
    const lightTheme = props.lightTheme;
    let logoSrc;

    useEffect(() => {
        async function getLogo() {
            const response = await api.get(`${axiosConfig.apiUrl}/Post/Media?parent=159&perpage=2`);
            setLogoSrcDark(response.data[0].url);
        }

        if (!onetime) {
            getLogo();
            setOneTime(true);
        }
    }, [onetime]);

    function checkStatus() {
        if (lightTheme.includes('no-scrolled') & lightTheme.includes('white_header')) {
            logoSrc = logoSrcLight;
            return;
        }
        logoSrc = logoSrcDark;
    }

    checkStatus();

    return <LogoWrapper className="LogoWrapper">
        <a href='/'>
            <img className="logo" src={logoSrc} alt="Logo Santillana" />
            <img className="logominor" src={logoSrcDark} alt="Logo Santillana" />
        </a>
    </LogoWrapper>

}
export default Logo;