import React from 'react';
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Carousel, { consts } from 'react-elastic-carousel';

import { Consultant, Maps } from '../../components';
import { Wrapper, Controllers, Left, Right } from './navigation.styled';
import mock from '../header/header.mock.json';
import controller from './icons/controller.png';

//BOTÕES PERSONALIZADOS
function arrows({ type, onClick, isEdge }) {
    const pointer = type === consts.PREV ?
        <Left src={controller} alt='Esquerda' /> :
        <Right src={controller} alt='Direita' />
    return (
        <Controllers onClick={onClick} disabled={isEdge}>
            {pointer}
        </Controllers>
    )
}

const Navigation = props => {
    const { list } = props.data || mock;
    const lightTheme = props.lightTheme;
    const isMobile = useMediaQuery({
        query: '(min-width: 576px)'
    })

    const listMenu = list.map(item => {
        return <NavLink 
            exact 
            activeClassName="active" 
            className={`header-menu`} 
            key={item.id} 
            to={item.permalink}>{item.title}
        </NavLink>
    })

    const breakPoints = [
        { width: 1, itemsToShow: 3 },
        { width: 250, itemsToShow: 4 }
    ];
    
    return <Wrapper id="menuPrincipal">        
        {
            !isMobile
            ?<Carousel
                    renderArrow={arrows}
                    pagination={false}
                    breakPoints={breakPoints}>
                    {listMenu}
                </Carousel>
            : listMenu
        }
        {
            isMobile
            ? <>
            <Consultant lightTheme={lightTheme} />
            <Maps lightTheme={lightTheme} />
            </>
            : null
        }
    </Wrapper>
}

export default Navigation;