import React from 'react';
import { Modal, ModalImg, ModalTitle, ModalDescription, ModalBtn, BoxModal, ComponentModal } from './modal-message.styled.js';
import iconSend from './assets/iconSuccess.png';

const ModalMessage = (props) => {

    const { title, description, buttonAction } = props;

    return <Modal>
        <BoxModal>
            <ComponentModal>
                <ModalImg src={iconSend} alt='' />
                <ModalTitle>{title}</ModalTitle>
                <ModalDescription>{description}</ModalDescription>
                <ModalBtn onClick={buttonAction}>OK</ModalBtn>
            </ComponentModal>
        </BoxModal>
    </Modal>
}

export default ModalMessage;