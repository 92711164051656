import React from 'react';
import { Spinner } from 'reactstrap';

import { ContentLoaderWrapper } from './content-loader.styled';

const ContentLoader = props => {
    return <ContentLoaderWrapper>
        <div className="loadercontainer">
            <Spinner className="loadercontainer__loader" />
        </div>
        <div className="loadercontainer__background"></div>
    </ContentLoaderWrapper>
}

export default ContentLoader;