import styled from 'styled-components';

export const LogoWrapper = styled.div`
    padding: 20px;
    width: 231px;
    height: 60px;
    box-sizing: unset;

    .logo {
        width: 231px;
        height: 60px;
        
        @media (max-width: 576px) {
            width: auto;
            height: 30px;
            padding: 0;
        }

        @media (max-width: 1199px) {
            display: none;
        }
    }

    .logominor {
        width: auto;
        height: 36px;
        padding: 0;
        margin: 0;

        @media (min-width: 768px) {
            width: 150px;
            height: auto;
        }

        @media (min-width: 992px) {
            width: 231px;
            height: 60px;
        }

        @media (min-width: 1200px) {
            display: none;
        }
    }

    a:before {
        display: none;
    }
`;