import axios from 'axios';
import { axiosConfig } from 'config';
import * as actions from 'store/actions';
import store from 'store';

const api = axios.create(axiosConfig);

api.interceptors.request.use(config => {
    store.dispatch(actions.loadingShow(`${config.method}:${config.url}`))
    return config;
});

api.interceptors.response.use(response => {
    store.dispatch(actions.loadingHide(`${response.config.method}:${response.config.url}`))

    return response;
}, error => {
    store.dispatch(actions.loadingHide("force-hide"))

    if (!error.response) {
        error = error.toJSON();
    }

    let alertProps = {
        color: 'danger',
        title: error.name,
        message: error.message
    }

    store.dispatch(actions.alertShow(alertProps))
    // TODO: chame a action do erro aqui
    return Promise.reject(error);
});

export default api;