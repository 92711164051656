import React, { useEffect, useState } from 'react';
import { ICWrapper, ICHeader, ICHTitle, ICHDescription, ICBody } from './internal-content.styled';

const InternalContent = props => {
    const [isSafari, setIsSafari] = useState(false);

    //identifica o browser para aumentar o tamanho do header no print do relatorio pdf
    useEffect(() => {
        const ua = navigator.userAgent.toLowerCase();

        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {
                setIsSafari(false) // Chrome
            } else {
                setIsSafari(true) // Safari
            }
        }
    }, []);
    return <ICWrapper>
        <ICHeader className={`${props.filter === "on" ? "filter" : ""} ${props.brand ? `brand-${props.brand.slugBrands}` : ''}`} cover={props.cover}>

            <ICHTitle>{
                props.logo ?
                    <img
                        src={props.logo}
                        className={props.title ? `logo-${props.title.toLowerCase().split(' ').join('-')}` : ''}
                        alt={`Logo ${props.title}`}
                    /> :
                    props.title
            }</ICHTitle>

            {
                props.description || props.subtitle ? <ICHDescription>{props.description || props.subtitle}</ICHDescription> : null
            }
        </ICHeader>
        <ICBody className={`container margin-auto ${isSafari ? 'isSafari' : ''}  ${props.privacyPolicy ? 'isPrivacyPolicy' : ''}`}>{props.children}</ICBody>
    </ICWrapper>
}

export default InternalContent;