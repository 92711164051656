import React from "react";
import { Switch, Route } from "react-router-dom";

const Home = React.lazy(() => import('./views/home/home'));
const Institutional = React.lazy(() => import('./views/institutional/institutional'));
// const NotFound = React.lazy(() => import('./views/404/404'));
const Contents = React.lazy(() => import('./views/contents/contents'));
const Content = React.lazy(() => import('./views/contents/content'));
const Simulador = React.lazy(() => import('./views/simulator/simulator'));
const Search = React.lazy(() => import('./views/search/search'));
const MapOfSite = React.lazy(() => import('./views/map-of-site/map-of-site'));
const Solutions = React.lazy(() => import('./views/solutions/solutions'));
const Brands = React.lazy(() => import('./views/brands/brands'));
const Future = React.lazy(() => import('./views/future/future'));
const Society = React.lazy(() => import('./views/society/society'));
const History = React.lazy(() => import('./views/history/history'));
const HistoryItem = React.lazy(() => import('./views/history/history-item'));
const UserDataRights = React.lazy(() => import('./views/user-data-rights/user-data-rights'));

const Social = React.lazy(() => import('./views/social/social'));
const Values = React.lazy(() => import('./views/values/values'));
const Page = React.lazy(() => import('./views/generic/page'));
const Category = React.lazy(() => import('./views/generic/category'));
const Post = React.lazy(() => import('./views/generic/post'));
const ConteudoInterno = React.lazy(() => import('./views/generic/conteudo-interno'));
const Contact = React.lazy(() => import('./views/contact/contact'));
const Consultant = React.lazy(() => import('./views/consultant/consultant'));
const PrivacyPolicy = React.lazy(() => import("./views/privacy-policy/privacy-policy"));
const PrivacyPolicyFirstPage = React.lazy(() => import("./views/privacy-policy/privacy-policy-first-page/privacy-policy-first-page"));

const Routes = () => {
    // 
    if (window.location.pathname !== "/consultor") {
        if (window.location.pathname === "/") {
            window.localStorage.setItem('@sta-origin', "Home");
        } else if (window.location.pathname !== "/" && document.title !== "Santillana") {
            window.localStorage.setItem('@sta-origin', document.title);
        }
    }

    return <Switch>
        <Route exact path="/aviso-de-privacidade">
            <PrivacyPolicyFirstPage />
        </Route>
        <Route exact path="/aviso-de-privacidade-geral">
            <PrivacyPolicy />
        </Route>
        <Route exact path="/">
            <Home />
        </Route>
        <Route exact path="/institucional">
            <Institutional />
        </Route>
        <Route exact path="/institucional/:slug">
            <ConteudoInterno filter="on" />
        </Route>
        <Route exact path="/conteudos">
            <Contents />
        </Route>
        <Route exact path="/conteudos/:slug">
            <Content />
        </Route>
        <Route path="/simulador">
            <Simulador />
        </Route>
        <Route exact path="/busca">
            <Search />
        </Route>
        <Route exact path="/mapa-do-site">
            <MapOfSite />
        </Route>
        <Route exact path="/nossas-solucoes">
            <Solutions />
        </Route>
        <Route exact path="/nossas-solucoes/:slug">
            <ConteudoInterno filter="off" />
        </Route>
        <Route exact path="/nossas-marcas">
            <Brands />
        </Route>
        <Route exact path="/nossas-marcas/:slug">
            <ConteudoInterno filter="off" />
        </Route>
        <Route exact path="/nossa-historia">
            <History />
        </Route>
        <Route exact path="/nossa-historia/:historiaId">
            <HistoryItem />
        </Route>

        <Route exact path="/impacto-social">
            <Social />
        </Route>
        <Route exact path="/valores">
            <Values />
        </Route>
        <Route exact path="/futuro">
            <Future />
        </Route>
        <Route exact path="/sociedade">
            <Society />
        </Route>
        <Route exact path="/solicitacao-de-direitos-de-dados/:id">
            <UserDataRights />
        </Route>
        <Route exact path="/page/:slug">
            <Page />
        </Route>
        <Route exact path="/category/:slug">
            <Category />
        </Route>
        <Route exact path="/post/:slug">
            <Post />
        </Route>
        <Route exact path="/contato">
            <Contact />
        </Route>
        <Route exact path="/consultor">
            <Consultant origin={origin} />
        </Route>
        <Route exact path="/:slug">
            <ConteudoInterno filter="on" />
        </Route>
    </Switch>
}

export default Routes;