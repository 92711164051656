import styled from 'styled-components';

export const SearchWrapper = styled.div `
    
`;

export const IconSearch = styled.img `
    
`;

export const IconClosed = styled.div `
    
`;

export const ResultWrapper = styled.div `
    
`;