import styled from "styled-components";

export const ICWrapper = styled.section`
  background-color: #fff;
`;

export const ICHeader = styled.header`
  padding: 50px 0px 100px;
  min-height: 264px;
  background-size: cover;
  background-position: center;
  background-image: url(${(props) => props.cover});

  @media print {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 555;
    min-height: 300px;

    font-size: 36px !important;
    visibility: visible;
    -webkit-print-color-adjust: exact;
    padding: 120px 0px 120px !important;
  }

  @media (min-width: 992px) {
    min-height: unset;
    padding: 150px 0 400px;
  }

  @media (max-width: 765px) {
    min-height: 160px;
    padding: 50px 0px 100px;
  }

  &.filter {
    background-image: radial-gradient(
        circle at 88% 0,
        rgba(0, 192, 216, 0.7),
        #002a6f 83%
      ),
      url(${(props) => props.cover});
  }

  &.brand-uno {
    background-image: linear-gradient(
        to right,
        rgba(0, 102, 138, 0.7),
        rgba(110, 114, 116, 0.4) 60%,
        rgba(110, 114, 116, 0.7)
      ),
      url(${(props) => props.cover});
  }

  &.brand-compartilha-educacional {
    background-image: linear-gradient(
        to right,
        rgba(92, 89, 159, 0.7),
        rgba(184, 118, 170, 0.4) 60%,
        rgba(184, 118, 170, 0.7)
      ),
      url(${(props) => props.cover});
  }

  &.brand-kepler {
    background-image: linear-gradient(
        to right,
        rgba(237, 19, 95, 0.7),
        rgba(247, 157, 119, 0.4) 60%,
        rgba(247, 157, 119, 0.7)
      ),
      url(${(props) => props.cover});
  }

  &.brand-pitangua {
    background-image: linear-gradient(
        to right,
        rgba(8, 189, 176, 0.7),
        rgba(212, 202, 105, 0.4) 60%,
        rgba(212, 202, 105, 0.7)
      ),
      url(${(props) => props.cover});
  }

  &.brand-ed-moderna {
    background-image: linear-gradient(
        to right,
        rgba(202, 40, 109, 0.7),
        rgba(247, 203, 57, 0.4) 60%,
        rgba(9, 151, 213, 0.7)
      ),
      url(${(props) => props.cover});
  }

  &.brand-educate {
    background-image: linear-gradient(
        to right,
        rgba(67, 53, 140, 0.7),
        rgba(229, 0, 81, 0.4) 60%,
        rgba(51, 91, 157, 0.7)
      ),
      url(${(props) => props.cover});
  }

  &.brand-richmond-livros {
    background-image: linear-gradient(
        to right,
        rgba(92, 89, 159, 0.7),
        rgba(184, 118, 170, 0.4) 60%,
        rgba(184, 118, 170, 0.7)
      ),
      url(${(props) => props.cover});
  }

  &.brand-richmond {
    background-image: linear-gradient(
        to right,
        rgba(92, 89, 159, 0.7),
        rgba(184, 118, 170, 0.4) 60%,
        rgba(184, 118, 170, 0.7)
      ),
      url(${(props) => props.cover});
  }

  &.brand-santillana-espanhol {
    background-image: linear-gradient(
        to right,
        rgba(196, 20, 20, 0.7),
        rgba(247, 203, 57, 0.4) 60%,
        rgba(196, 20, 20, 0.7)
      ),
      url(${(props) => props.cover});
  }

  &.brand-crescemos-educacional {
    background-image: linear-gradient(
        to right,
        rgba(244, 194, 56, 0.7),
        rgba(244, 194, 56, 0.4) 60%,
        rgba(38, 175, 197, 0.7)
      ),
      url(${(props) => props.cover});
  }

  &.brand-territorio-da-leitura {
    background-image: linear-gradient(
        to right,
        rgba(72, 61, 92, 0.7),
        rgba(123, 83, 129, 0.4) 60%,
        rgba(148, 84, 123, 0.7)
      ),
      url(${(props) => props.cover});
  }

  &.brand-salamandra {
    background-image: linear-gradient(
        to right,
        rgba(232, 138, 66, 0.7),
        rgba(232, 138, 66, 0.4) 60%,
        rgba(232, 138, 66, 0.7)
      ),
      url(${(props) => props.cover});
  }

  &.brand-moderna-literatura {
    background-image: linear-gradient(
        to right,
        rgba(92, 89, 159, 0.7),
        rgba(184, 118, 170, 0.4) 60%,
        rgba(184, 118, 170, 0.7)
      ),
      url(${(props) => props.cover});
  }

  &.brand-avalia-educacional {
    background-image: linear-gradient(
        to right,
        rgba(202, 40, 109, 0.7),
        rgba(137, 166, 72, 0.4) 60%,
        rgba(51, 91, 157, 0.7)
      ),
      url(${(props) => props.cover});
  }

  &.brand-richmond-solution {
    background-image: linear-gradient(
        to right,
        rgba(228, 30, 71, 0.7),
        rgba(228, 30, 71, 0.4) 60%,
        rgba(122, 37, 123, 0.7)
      ),
      url(${(props) => props.cover});
  }

  &.brand-sfb {
    background-image: linear-gradient(
        to right,
        rgba(30, 82, 152, 0.7),
        rgba(70, 156, 99, 0.4) 60%,
        rgba(70, 156, 99, 0.7)
      ),
      url(${(props) => props.cover});
  }
  &.brand-aprova-brasil {
    background-image: linear-gradient(
        to right,
        rgba(197, 157, 93, 0.7),
        rgba(138, 182, 126, 0.4),
        rgba(95, 178, 189, 0.7)
      ),
      url(${(props) => props.cover});
  }
  &.brand-pnld {
    background-image: linear-gradient(
        to right,
        rgba(202, 40, 109, 0.7),
        rgba(247, 203, 57, 0.6) 66%,
        rgba(9, 151, 213, 0.7)
      ),
      url(${(props) => props.cover});
  }
`;

export const ICHTitle = styled.h1`
  font-family: TitilliumWeb-Regular;
  font-size: 38px;
  line-height: 1;
  font-stretch: normal;
  font-style: normal;

  letter-spacing: -0.3px;
  color: rgba(255, 255, 255, 0.85);
  width: 90%;
  margin: auto;
  text-align: center;

  @media (min-width: 992px) {
    font-size: 60px;
  }

  @media print {
    font-size: 38px;
    font-weight: normal;
  }

  img {
    &.logo-aprova-brasil {
      max-width: 171px;

      @media (min-width: 992px) {
        max-width: 255px;
      }
    }
    &.logo-pnld {
      max-width: 254px;

      @media (min-width: 992px) {
        max-width: 404px;
      }
    }
  }
`;
export const ICHDescription = styled.p`
  font-family: TitilliumWeb-Regular;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  width: 70%;
  margin: 20px auto 0;
  text-align: center;
  color: #ffffff;

  @media (min-width: 992px) {
    font-size: 30px;
    line-height: 1.07;
  }

  @media print {
    font-family: TitilliumWeb-Light;
    font-size: 28px;
    width: 45%;
  }
`;

export const ICBody = styled.article`
  margin: -50px auto 50px;
  padding: 30px 25px;
  background-color: #fff;
  border-radius: 50px;
  overflow: hidden;

  @media (min-width: 992px) {
    margin: -300px auto 50px;
    padding: 60px 80px;
    background-color: #fff;
    border-radius: 100px;
    min-height: 500px;

    &.isPrivacyPolicy {
      border-right: 1px solid #e1e1db;
      border-bottom: 1px solid #e1e1db;
      border-bottom-left-radius: 0px;
    }
  }

  @media print {
    position: relative;
    margin: 150px auto 70px !important;
    padding: 60px 80px 0;
    background-color: #fff;
    z-index: 555;
    height: 100%;

    &.isSafari {
      margin: 300px auto 70px !important;
    }
  }
`;
