import React from 'react';

import { ConsultantWrapper, IconConsultant, IconConsultantResponsive } from './consultant.styled.js';
import iconConsultant from './img/consultorDark.svg';
import iconConsultantWhite from './img/consultorWhite.svg';

const Consultant = props => {
    const lightTheme = props.lightTheme;
    let imgSrc;

    function checkStatus() {
        if (lightTheme.includes('no-scrolled') & lightTheme.includes('white_header')) {
            imgSrc = iconConsultantWhite;
            return;
        }
        imgSrc = iconConsultant;
    }

    checkStatus();

    return <ConsultantWrapper href='/consultor'>
        <IconConsultant src={imgSrc} alt='' />
        <IconConsultantResponsive src={iconConsultant} alt="" />
    </ConsultantWrapper>
}

export default Consultant;