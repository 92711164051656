import {
  LOG_IN,
  LOG_OUT
} from '../actions/type';

let token = localStorage.getItem('@sta-user-token');

const initialState = { data: token } || { data: null };

const Token = (state = initialState, action) => {
  switch (action.type) {
    case LOG_IN:
      return {
        ...state,
        data: action.payload
      }
    case LOG_OUT:
      return {
        data: null
      }
    default:
      return state;
  }
}

export default Token;