import './menu-dots.scss';
import 'default-passive-events';

import React, { useEffect, useState } from 'react';
import smoothscroll from 'smoothscroll-polyfill';

import MenuDotsWrapper from './menu.dots.styled.js';

const MenuDots = (props) => {
  const { children, data, themes, manualPosition } = props;
  const [scrollYPosition, setScrollYPosition] = useState(0);
  const [position, setPosition] = useState();
  const [listItems, setListItems] = useState([]);
  const [menuPosition, setMenuPosition] = useState("sem-footer");
  const [activedItem, setActivedItem] = useState("sobre");

  const scrollTo = (event, idSection) => {
    // console.log('scrollTo', idSection);

    event.preventDefault();
    setActivedItem(idSection);

    try {
      let atualPosition;

      switch (idSection) {
        case "presenca-international":
          atualPosition = document.querySelector('#presencainternacional').offsetTop - 10;
          break;

        case "presenca-nacional":
          atualPosition = (document.querySelector('#presencainternacional').offsetTop + (window.innerHeight * .75)) + 50;
          break;

        case "nossa-historia":
          if (navigator.userAgent.indexOf('Safari') > -1) {
            // Safari
            atualPosition = document.querySelector('#nossa-historia').offsetTop;
          } else {
            atualPosition = position.filter((item) => item.id === idSection)[0].start;
          }

          break;

        case "inovacao":
          atualPosition = document.querySelector('#inovacao').offsetTop - 80;

          break;

        case "impacto-social":
          atualPosition = document.querySelector('#impacto-social').offsetTop - 80;

          break;

        default:
          atualPosition = position.filter((item) => item.id === idSection)[0].start;
          break;
      }

      window.scrollTo({ top: atualPosition, left: 0, behavior: "smooth" });
    } catch (error) {
      try {
        const top = querySelector("section#" + idSection).offsetTop - 54;

        window.scrollTo({
          top: top,
          left: 0,
          behavior: "smooth",
        });
      } catch (error) { }
    }
  };

  useEffect(() => {
    if (data) {
      setListItems(data);
    } else if (manualPosition) {
      setListItems(manualPosition);
    }
  }, [data, manualPosition]);

  useEffect(() => {
    function handleScroll() {
      if (activedItem === 'presenca-international') {
        let meio = window.presencainternacional.offsetTop + (window.presencainternacional.offsetHeight / 2);
        meio = meio - 120; // desconto do header e margens

        if (window.scrollY > meio) {
          setActivedItem('presenca-nacional')
        }
      } else if (activedItem === 'presenca-nacional') {
        if (window.scrollY < window.presencainternacional.offsetTop) {
          setActivedItem('presenca-international')
        }
      }
    }

    smoothscroll.polyfill();
    document.addEventListener("scroll", handleScroll());

    return () => window.removeEventListener("scroll", handleScroll);
  });

  function querySelector(selector) {
    return document.querySelector(selector);
  }

  function markIsActived() {
    try {
      buildPosition();

      const scrollYPositionInternal = scrollYPosition + 70;
      const sectionActived = position.filter(
        (data) =>
          data.start <= scrollYPositionInternal &&
          scrollYPositionInternal <= data.end
      )[0];

      const oldElement = querySelector(".dot-list li.active");
      const newElement = querySelector(".dot-list li#dot-" + sectionActived.id);

      if (oldElement) {
        // console.log('oldElement', oldElement);
        oldElement.className = "";
      }

      if (newElement) {
        newElement.className = "active";
      }

      querySelector(".cd-vertical-nav ul").className = `${sectionActived.theme} dot-list`;
    } catch (error) { }
  }

  function buildPosition() {
    if (position === undefined && manualPosition === undefined) {
      setTimeout(() => {
        if (data) buildPositionFromData();
      }, 500);
    }

    if (position === undefined && manualPosition && data === undefined) {
      buildPositionFromPositionManual();
    }
  }

  function buildPositionFromData() {
    // console.log(data);
    const positionFromData = data.map((item, index) => {
      let startPosition;
      let endPosition;
      const idSectionData = item.id;
      const sectionElement = querySelector("section#" + idSectionData);
      startPosition = index === 0 ? 0 : sectionElement.offsetTop;
      endPosition =
        index === data.length
          ? sectionElement.offsetTop + sectionElement.offsetHeight + 1000
          : sectionElement.offsetTop + sectionElement.offsetHeight;

      try {
        const idSectionDataNext = data[index + 1].id;
        if (
          querySelector("section#" + idSectionDataNext).offsetTop <= endPosition
        ) {
          endPosition =
            querySelector("section#" + idSectionDataNext).offsetTop - 1;
        }
      } catch (error) { }

      try {
        const idSectionDataPrev = data[index - 1].id;
        if (
          querySelector("section#" + idSectionDataPrev).offsetTop ===
          startPosition
        ) {
          return false;
        }
      } catch (error) { }

      return {
        id: idSectionData,
        start: startPosition,
        end: endPosition,
        theme: themes[index],
      };
    });

    setPosition(positionFromData);
  }

  function buildPositionFromPositionManual() {
    const positionManualAtual = manualPosition.map((item, index) => {
      let endPosition;
      if (index < manualPosition.length - 1) {
        endPosition = manualPosition[index + 1].start - 1;
      } else {
        endPosition = item.start + 1300;
      }

      return {
        id: item.id,
        start: item.start,
        end: endPosition,
        theme: item.theme,
      };
    });

    setPosition(positionManualAtual);
  }

  useEffect(() => {
    var intersectionObserver = new IntersectionObserver(function (entries) {
      if (entries[0].intersectionRatio !== 0) {
        setMenuPosition("com-footer");
      } else {
        setMenuPosition("sem-footer");
      }
    });

    intersectionObserver.observe(document.querySelector('#rodape'));

    if (
      document.querySelectorAll("#view-intitutional section[id]").length > 0 ||
      document.querySelectorAll("#view-home section[id]").length > 0
    ) {
      var intersectionObserverSections = new IntersectionObserver(function (entries) {
        entries.forEach(entry => {
          let activeitemId = entry.target.id;
          // console.log(activeitemId);

          if (entry.isIntersecting) {
            if (activeitemId === 'presencainternacional') {
              if (window.scrollY < window.presencainternacional.offsetHeight) {
                setActivedItem('presenca-international');
              } else {
                setActivedItem('presenca-nacional');
              }
            } else if (activeitemId === 'sociedade') {
              setActivedItem('futuro');
            } else {
              setActivedItem(activeitemId);
            }
          }
        })
      }, {
        threshold: .5
      });

      var elementList = document.querySelectorAll("#view-intitutional section[id], #view-home section[id]");

      elementList.forEach(e => {
        intersectionObserverSections.observe(e);
      })
    }

    let delay = window.setInterval(() => {
      if (document.querySelector('#conteudos')) {
        intersectionObserverSections.observe(document.querySelector('#conteudos'));
        clearInterval(delay);
      }
    }, 1000)
  }, [])

  return listItems ? (
    <>
      <MenuDotsWrapper className={`${menuPosition} menu-dots-safari`}>
        <nav className="cd-vertical-nav">
          <ul className="dot-list">
            {listItems.map((item, index) => {
              if (item.id !== "sociedade") {
                return (
                  <li
                    key={index}
                    id={"dot-" + item.id}
                    className={`${activedItem === item.id ? "active" : ""}`}
                  >
                    <a href={`#${item.id}`} onClick={(e) => scrollTo(e, item.id)}>
                      <span className="cd-dot">{ }</span>
                      <span className="cd-dot-line"></span>
                    </a>
                  </li>
                );
              } else {
                return null;
              }
            })}
          </ul>
        </nav>

        {children}
      </MenuDotsWrapper>
    </>
  ) : null;
};

export default MenuDots;
