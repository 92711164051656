import {
    ALERT_SHOW,
    ALERT_HIDE,
} from './type';

const alertShow = ({
    color,
    title,
    message
}) => {
    return {
        type: ALERT_SHOW,
        color: color,
        title: title,
        message: message
    }
}

const alertHide = () => {
    return {
        type: ALERT_HIDE
    }
}

export {
    alertShow,
    alertHide,
}