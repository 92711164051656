import React, { createContext, useState, useContext, useEffect } from 'react';

import { api } from 'utils';
import { axiosConfig } from 'config';
import { Loader } from 'components';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [apiToken, setApiToken] = useState('');

    useEffect(() => {
        let loginAPI = {};

        if (window.location.host === 'www.gruposantillana.com.br' ||
            window.location.host === 'www.santillana.com.br' ||
            window.location.port === '5000') {
            // Login produção
            loginAPI = {
                "User": "SantillanaPortalAPI",
                "Pass": "0#2fc&78$f2!9"
            };
        } else {
            // Login dev e homolog
            loginAPI = {
                "User": "PortalAPI",
                "Pass": "WxqF6gwaUB986"
            };
        }
        api.post(`${axiosConfig.apiUrl}/Auth`, loginAPI).then(response => {
            const { acessToken } = response.data;
            localStorage.setItem('@sta-token', acessToken);
            api.defaults.headers.authorization = `Bearer ${acessToken}`;
            setApiToken(acessToken);
        });
    }, []);


    return (
        <AuthContext.Provider value={{ apiToken }}>
            {apiToken ?
                children :
                <Loader />
            }
        </AuthContext.Provider>
    )
}

export function useAuth() {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }

    return context;
}