import styled from "styled-components";

export const Modal = styled.div`
  position: relative;
  z-index: 999;
`;
export const BoxModal = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ComponentModal = styled.div`
  background-color: #fff;
  border-radius: 6px;
  width: 342px;
  /* height: 367px; */
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const ModalImg = styled.img`
  margin-bottom: 36px;
`;
export const ModalTitle = styled.h6`
  font-size: 18px;
  font-family: TitilliumWeb-Regular;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: #231d19;
`;
export const ModalDescription = styled.p`
  font-size: 14px;
  font-family: TitilliumWeb-Regular;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #231d19;
  margin-bottom: 40px;
`;
export const ModalBtn = styled.div`
  display: block;
  padding: 13px 0;
  width: 246px;
  border-radius: 25px;
  background-color: #0095d3;
  color: #fff;
  text-align: center;
  cursor: pointer;
`;
