import {
    LOADING_SHOW,
    LOADING_HIDE,
} from './type';

const loadingShow = (origin) => {
    return {
        type: LOADING_SHOW,
        visible: true,
        origin
    }
}

const loadingHide = (origin) => {
    return {
        type: LOADING_HIDE,
        visible: false,
        origin
    }
}

export {
    loadingShow,
    loadingHide,
}
