import 'default-passive-events';

import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Logo, Menu, Navigation, Consultant, Maps } from '../../components';
import { HeaderWrapper, BoxMobile } from './header.styled';

const Header = props => {
    const [scrollYPosition, setScrollYPosition] = useState(0);
    const [atualPath, setAtualPath] = useState('');
    const isMobile = useMediaQuery({
        query: '(min-width: 576px)'
    })
    const pos = props.pos;
    let headerClass;

    useEffect(() => {
        let timerEvent;
        function handleScroll() {
            if (scrollYPosition !== window.pageYOffset) {
                setScrollYPosition(window.pageYOffset);
            }
        }

        document.addEventListener("scroll", handleScroll(), { passive: true });

        timerEvent = setInterval(
            () => {
                if (atualPath !== window.location.pathname) {
                    setAtualPath(window.location.pathname);
                }
            }
            , 300
        );

        return () => {
            window.removeEventListener('scroll', handleScroll);
            clearInterval(timerEvent);
            timerEvent = null;
        }
    });

    function isWhiteHeader() {
        const pathnames = [
            '/institucional'
        ];
        return pathnames.filter(
            (path) => path === atualPath
        ).length > 0;
    }

    function setClasses() {

        headerClass = classNames({
            'no-scrolled': scrollYPosition < 75,
            'scrolled': scrollYPosition >= 75,
            'white_header': isWhiteHeader(),
        });
    }

    setClasses();

    return <HeaderWrapper id="header" className={headerClass}>
        <Menu pos={pos}>

            {
                !isMobile
                    ? <BoxMobile>
                        <Maps lightTheme={headerClass} />
                        <Logo className={headerClass} lightTheme={headerClass} />
                        <Consultant lightTheme={headerClass} />
                    </BoxMobile>
                    : <Logo className={headerClass} lightTheme={headerClass} />
            }
            <div className="menu-itens">
                <Navigation lightTheme={headerClass} scrollYPosition={scrollYPosition} />
            </div>

        </Menu>
    </HeaderWrapper>
}

export default Header;