import * as type from '../actions/type';

const initialState = {
    visible: false,
    origin: []
}

const Loading = (state = initialState, action) => {
    // console.log('state', state);
    // console.log('action', action);
    // console.log(state.origin.length);

    switch (action.type) {
        case type.LOADING_SHOW:
            return {
                visible: action.visible,
                origin: [...state.origin, action.origin]
            }
        case type.LOADING_HIDE:
            let forceHide = false;

            const origin = state.origin.filter(item => {
                if (action.origin === "force-hide") {
                    forceHide = true;
                }

                return item === action.origin ? null : item
            })

            let visible;

            if (forceHide) {
                visible = false;
            } else {
                visible = origin.length > 0;
            }

            return {
                visible,
                origin
            }
        default:
            return state;
    }
}

export default Loading;