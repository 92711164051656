import styled from 'styled-components';

export const Wrapper = styled.div`
    .barraAcessivel {
        background-color: #000;

        .container {
            margin: auto;
            padding: 0;
            box-sizing: content-box;

            .row {
                margin: 0;
                display: flex;
                justify-content: space-between;
            }
        }

        a, button {
            font-size: 13px;
            color: #FFFFFF;
            letter-spacing: 0;
            text-decoration: none;

            &:hover {
                text-decoration: none;
                color: #FFFFFF;
            }
        }

        li.contraste {
            &:hover,
            &:active {
                .dautoniveis  {
                    display: block;
                    opacity: 1;
                    height: auto;
                    width: auto;
                    background-color: #000;

                    button {
                        position: relative;
                        white-space: nowrap;

                        &:not(.completed):before {
                            text-decoration: none;
                            content: "";
                            position: absolute;
                            width: 100%;
                            height: 1px;
                            bottom: 0px;
                            left: 0;
                            background-color: #ffffff;
                            visibility: hidden;
                            -webkit-transform: scaleX(0);
                            transform: scaleX(0);
                            -webkit-transition: all .3s cubic-bezier(1,.25,0,.75) 0s;
                            transition: all .3s cubic-bezier(1,.25,0,.75) 0s;
                        }
                                
                        &:not(.completed):hover:before {
                            text-decoration: none;
                            visibility: visible;
                            -webkit-transform: scaleX(1);
                            transform: scaleX(1);
                        }
                    }                    
                }
            }
        }

        /* .oculto {
            display: none;
        } */
    }
    @media (min-width: 991px){
        .pull-right {
            display: flex;
        }
        .barraAcessivel {
            width: 100%;
            .right-mobile{
                display: flex !important;

                button.contraste:before {
                    top: 13px;
                    margin-left: -22px;
                    padding: 12px 0;
                }
            }
        }
    }
    @media (max-width: 768px){
        .barraacessivel {
            display: flex;
            width: 100%;
            .dautoniveis {
                right: 0;
            }
        }
        .barraAcessivel {
            .pull-right{
                width: 100%;

                .right-mobile {
                    display: flex;
                    width: fit-content;
                    margin-left: auto;
                }
            }
        }
    }
`;