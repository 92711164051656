import React from 'react';
import { Spinner } from 'reactstrap';
import { LoaderWrapper } from './loader.styled.js';

const Loader = props => {
    let { visible } = props;

    if (typeof visible === undefined) visible = true;

    return visible ? <LoaderWrapper>
        <Spinner />
    </LoaderWrapper> : null;
}

export default Loader;