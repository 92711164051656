import styled from 'styled-components';

export const AlertWrapper = styled.div`
    background-color: #fff;
    opacity: 1;
    z-index: 1000;
    position: fixed;
    
    .alert-title {
        margin-right: 10px  
    }

    .danger {
        display: block;
        position: fixed;
        top: 35px;
        left: 0;
        right: 0;
    }

    .success {
        position: absolute;
        top: 0;
        right: 0;
        margin: 100px 0 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .warning {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 10px 20px;
    }

    .info {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 10px 20px;
    }
`;