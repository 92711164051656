import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: #7dd1eb;
  position: relative;
`;

export const Text = styled.p`
  margin: 0;
  padding: 8px 16px;
  text-align: center;
  color: #000;
  font-size: 14px;
  font-family: TitilliumWeb-Regular;

  a {
    text-decoration: underline;
    color: #000;
  }
`;

export const CloseButton = styled.button`
  height: 38px;
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  background: none;
  font-size: 24px;
  text-align: center;
  padding: 0;
  width: 38px;

  .bi {
    font-size: 24px;
    color: #000;
  }
`;
