import "./../../assets/scss/index.scss";

import styled from "styled-components";

export const BoxMobile = styled.div`
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;

  justify-content: space-between;
  -webkit-box-pack: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;

  align-items: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
`;

export const HeaderWrapper = styled.div`
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: transparent;
  font-family: "TitilliumWeb-Light", sans-serif;

  .content-header-logo {
    overflow: hidden;
    width: 240px;
    height: 60px;

    .header-logo {
      width: auto;
      height: auto;
    }
  }

  .content-header-right {
    display: flex;

    li {
      list-style-type: none;
    }

    .content-header-box ul {
      display: flex;
      flex-direction: row;

      .header-menu {
        text-decoration: none;
        margin: 10px 30px;
        font-size: 20px;
        opacity: 0.6;
        font-size: 20px;
        color: #ccc;
        letter-spacing: 0;

        @media (min-width: 576px) {
          font-size: 14px;
        }
      }

      .header-menu:hover {
        text-decoration: none;
        margin: 10px 30px;
        font-size: 20px;
        opacity: 0.6;
        font-size: 20px;
        color: #ccc;
        letter-spacing: 0;
      }
    }
  }

  .content-header-lupa,
  .content-header-maps {
    margin-left: 30px;
  }

  .content-header-lupa {
    .lupa a {
      text-decoration: none;
      color: #fff;
    }
  }

  .content-header-maps {
    .maps a {
      text-decoration: none;
      color: #fff;
      text-align: end;
    }
  }

  &.no-scrolled {
    background-color: #ffffff;
    background-color: transparent;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0);
    transition: background-color 0.75s ease;
    transition: box-shadow 0.5s ease;

    .LogoWrapper {
      width: auto;
      padding: 9px 20px;
      height: auto;
      z-index: 10;

      .logo {
        height: 48px;
        transition: height 0.5s ease;
        width: auto;
      }
    }

    .menu-itens {
      padding: 9px 0px;
      z-index: 10;

      @media (min-width: 1200px) {
        padding: 9px 20px;
      }

      a {
        color: #212121;
        font-size: 14px;
        transition: color 0.5s ease;
        position: relative;

        @media (min-width: 768px) {
          font-size: 16px;
        }

        &:hover {
          opacity: 1;
        }

        &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: 0;
          left: 0;
          opacity: 0.6;
          background-color: #000000;
          visibility: hidden;
          -webkit-transform: scaleX(0);
          transform: scaleX(0);
          -webkit-transition: all 0.3s cubic-bezier(1, 0.25, 0, 0.75) 0s;
          transition: all 0.3s cubic-bezier(1, 0.25, 0, 0.75) 0s;
        }

        &:hover:before {
          visibility: visible;
          opacity: 1;
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
        }

        &.active:hover:before,
        &[aria-current="page"]:hover:before {
          visibility: hidden;
        }
      }
    }
  }

  &.no-scrolled.white_header {
    color: #212121;

    @media (min-width: 1200px) {
      .menu-itens {
        a {
          color: #ffffff;

          &:before {
            background-color: #ffffff;
          }
        }
      }
    }
  }

  &.scrolled {
    background-color: #ffffff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
    position: sticky;
    transition: background-color 0.75s ease;
    top: 0;

    .LogoWrapper {
      width: auto;
      padding: 9px 20px;
      height: auto;
      z-index: 10;

      .logo {
        height: 36px;
        transition: height 0.5s ease;
        width: auto;
      }
    }

    .menu-itens {
      padding: 9px 0px;
      z-index: 10;

      @media (min-width: 1200px) {
        padding: 9px 20px;
      }

      a {
        color: #000;
        font-size: 14px;
        transition: color 0.5s ease;
        position: relative;

        @media (min-width: 768px) {
          font-size: 16px;
        }

        &:hover {
          opacity: 1;
        }

        &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: 0;
          left: 0;
          background-color: #000000;
          visibility: hidden;
          -webkit-transform: scaleX(0);
          transform: scaleX(0);
          -webkit-transition: all 0.3s cubic-bezier(1, 0.25, 0, 0.75) 0s;
          transition: all 0.3s cubic-bezier(1, 0.25, 0, 0.75) 0s;
        }

        &:hover:before {
          visibility: visible;
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
        }

        &.active:hover:before,
        &[aria-current="page"]:hover:before {
          visibility: hidden;
        }
      }
    }
  }

  a[aria-current="page"] {
    opacity: 1;
    font-family: TitilliumWeb-Bold;
  }
`;
