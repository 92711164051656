import styled from "styled-components";

export const FooterWrapper = styled.footer`
  position: relative;
  z-index: 10;
  background: #2e2e2e;
  .footer-contents {
    padding: 30px 0;
    color: #ffffff;

    ul,
    li {
      list-style: none;
      display: block;
      padding: 0;
      margin: 0;
    }

    ul {
      margin-bottom: 24px;
    }

    a {
      text-decoration: none;
      color: #e1e1e1;
      position: relative;

      &:not(.completed):before {
        text-decoration: none;
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: 0px;
        left: 0;
        background-color: #ffffff;
        visibility: hidden;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transition: all 0.3s cubic-bezier(1, 0.25, 0, 0.75) 0s;
        transition: all 0.3s cubic-bezier(1, 0.25, 0, 0.75) 0s;
      }

      &:not(.completed):hover:before {
        text-decoration: none;
        visibility: visible;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
      }
    }

    .footer-container {
      .footer-links {
        display: flex;
        flex-wrap: wrap;

        div {
          margin: 10px;
          @media (max-width: 576px) {
            min-width: 200px;
            margin: 0;
          }
          a {
            font-family: "TitilliumWeb-Regular";
            font-size: 18px;
            letter-spacing: 0;
            line-height: 30px;
          }

          strong {
            font-family: "TitilliumWeb-Medium";
            font-weight: normal;
            font-size: 18px;
            letter-spacing: 0;
            line-height: 30px;
          }
        }
      }

      @media (min-width: 1200px) {
        .footer-links {
          justify-content: space-between;
        }
      }

      .footer-text {
        text-align: center;
        padding-top: 20px;

        p {
          font-family: "TitilliumWeb-Light";
          font-size: 20px;
          letter-spacing: 0;
          line-height: 30px;
        }
      }

      .footer-icons {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 32px;
        right: 24px;
        .social-icons {
          display: block;
          margin: 0 0 24px 0;
          padding: 0;
          width: auto;
          height: 30px;
          box-sizing: border-box;
          float: right;

          transform: scale(1);
          -webkit-transition: all 0.3s cubic-bezier(1, 0.25, 0, 0.75) 0s;
          transition: all 0.3s cubic-bezier(1, 0.25, 0, 0.75) 0s;

          display: flex;
          align-items: center;
          justify-content: space-around;

          &:hover {
            transform: scale(1.15);
          }
        }
      }
    }

    .footer-copyright {
      font-family: TitilliumWeb-Light;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 30px;
      display: flex;
      justify-content: space-between;

      @media (max-width: 576px) {
        flex-wrap: wrap;
      }
      p {
        font-family: "TitilliumWeb-Light";
        margin-bottom: 0;
      }

      .divisor {
        font-family: "TitilliumWeb-Light";
        padding: 0 8px;
        &.first {
          display: none;
        }
        a {
          color: #fff;
        }
        @media (min-width: 1200px) {
          &.first {
            display: block;
          }
        }
      }

      .version {
        font-family: "TitilliumWeb-Regular";
        display: block;
        text-align: right;
        opacity: 0.3;
        @media (max-width: 576px) {
          /* margin-top: 15px; */
        }
      }
    }
  }

  @media (min-width: 1200px) {
    .footer-contents {
      .footer-container {
        display: flex;
        justify-content: space-between;

        .footer-links div {
          + div {
            margin-left: 60px;
          }
        }

        .footer-text {
          width: 350px;
          padding-top: 0;
        }

        .footer-icons {
          ul {
            display: unset;
          }
        }
      }

      .footer-copyright {
        text-align: left;

        p {
          margin-bottom: 0;
        }
      }

      .copyright {
        display: flex;

        span {
          padding: 0 10px;
        }

        @media (max-width: 576px) {
          margin-top: 12px;
        }

        /* a {
                    text-decoration-line: underline;
                } */
      }
    }
  }

  @media (max-width: 576px) {
    padding: 0 24px 24px 24px;
  }
`;

export const SocialIcon = styled.img``;
