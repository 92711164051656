import { combineReducers } from 'redux';

import Loading from './loading';
import Alert from './alert';
import Token from './token';

const reducers = combineReducers({
    Loading,
    Alert,
    Token
})

export default reducers;