import * as type from '../actions/type';

const initialState = {
    visible: false,
    color: '',
    message: '',
    title: '',
}

const Alert = (state = initialState, action) => {
    switch (action.type) {
        case type.ALERT_SHOW:
            return {
                state,
                visible: true,
                    color: action.color,
                    title: action.title,
                    message: action.message,
            }
            case type.ALERT_HIDE:
                return state;
            default:
                return state;
    }
}

export default Alert;