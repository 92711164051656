import React from 'react';

import { Wrapper } from './menu.styled';

const Menu = props => {
    const { children } = props;

    return <Wrapper className="container-fluid">
        <div className="container children-container">{children}</div>
    </Wrapper>
}

export default Menu;