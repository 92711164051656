import React from 'react';
import { axiosConfig } from 'config';
import { NavLink } from 'react-router-dom';

const Link = props => {
    let { children, href, className, target, to } = props;

    if (!href) href = "";

    if (href.indexOf('www') > -1 || href.indexOf('http') > -1) {
        if (!target && target === "") {
            target = "_blank";
        }

        return <a target={target} rel="noopener noreferrer" className={className} href={href} >{children}</a>;
    } else {
        if (!to) {
            to = {
                pathname: ""
            }

            href = href?.replace(axiosConfig.baseURL, "");
            href = href?.split("#");

            to.pathname = href[0];

            if (href[1]) {
                to.hash = `#${href[1]}`
            }
        }

        return <NavLink exact activeClassName="active" className={className} to={to} refresh="true">{children}</NavLink>
    }
}

export default Link;