import React from 'react';
import { UncontrolledAlert as RSAlert } from 'reactstrap';

import { AlertWrapper } from './alert.styled.js';

const Alert = props => {

    const { visible, color, title, message } = props;

    return visible ?
        <AlertWrapper>
            <RSAlert color={color} className={color}>
                {
                    title
                        ? <strong className="alert-title">{title}</strong>
                        : null
                }
                <span className="alert-message">{message}</span>
            </RSAlert>
        </AlertWrapper>
        : null
}

export default Alert;