import React from 'react';
import './css/santillanaAcessibilidadeCustom.css';
import { Wrapper } from './acessibilidade.styled';
import './css/sa.altocontraste.css';
import './css/sa.amarelopreto.css';
import './css/sa.cinzainvertida.css';
import './css/sa.corinvertida.css';
import './css/sa.monocromatico.css';

const Acessibilidade = () => {
    const zoomIn = () => {
        let current = Number(document.body.style.zoom) || 1;

        document.body.style.zoom = current += 0.2;
    }

    const zoomOut = () => {
        let current = Number(document.body.style.zoom) || 1;

        document.body.style.zoom = current -= 0.2;
    }

    const handlerSetNivel = e => {
        const el = document.getElementsByTagName('html');

        el[0].className = e.target.dataset.nivel;
    }

    return <Wrapper id="barraacessivel">
        <div className="barraAcessivel" role="menubar" aria-label="Barra de Acessibilidade">
            <div className="container">
                <div className="row">
                    <ul className="pull-left hidden-xs hidden-sm" aria-label="Navegação pela página">
                        <li role="menuitem">
                            <a href="#conteudo" title="Ir para o conteúdo">
                                <span lang="pt-br">Ir para o conteúdo</span> <span>[1]</span>
                            </a>
                        </li>
                        <li role="menuitem">
                            <a href="#menuPrincipal" title="Ir para o menu">
                                <span lang="pt-br">Ir para o menu</span> <span>[2]</span>
                            </a>
                        </li>
                        {/* <li role="menuitem" className="closeContrast"><a accessKey="3" href="#txtBusca" title="Ir para a busca"><span lang="pt-br">Ir para a busca</span> <span>[3]</span></a></li> */}
                        <li role="menuitem" className="closeContrast">
                            <a href="#rodape" title="Ir para o rodapé">
                                <span lang="pt-br">Ir para o rodapé</span> <span>[4]</span>
                            </a>
                        </li>
                    </ul>
                    <ul className="pull-right" aria-label="Controles de acessibilidade do site">
                        <li role="menuitem" className="closeContrast"><a href="/acessibilidade" title="Acessar a página de acessibilidade" className="pgacessibilidade"><span lang="pt-br">Acessibilidade</span></a> </li>
                        <li role="menuitem" className="livroAcessivel"><a href="https://www.moderna.com.br/acessibilidade-moderna/livro-acessivel/codigo/origem/50" title="Link para Livro Acessível : Link Externo" className="livroAcessivel"><span lang="pt-br">Livro acessível</span></a></li>
                        <li role="menuitem" className="zoomButtons">
                            <button title="Ampliar tela" onClick={zoomIn} className="zoomIn gm5zoom"><span lang="pt-br">A+</span></button>
                        </li>
                        <li role="menuitem" className="zoomButtons closeContrast">
                            <button title="Reduzir tela" onClick={zoomOut} className="zoomOut gm5zoom"><span lang="pt-br">A-</span></button>
                        </li>
                        <div className="right-mobile">
                            <li role="menuitem" className="contraste teste">
                                <button title="Contraste" className="contraste" aria-haspopup="true">
                                    <span className="oculto" lang="pt-br">Contraste</span>
                                </button>
                                <div className="dautoniveis closed" aria-label="submenu">
                                    <ul>
                                        <li><button onClick={handlerSetNivel} title="Preto, branco e amarelo" data-nivel="amarelopreto">Preto, branco e amarelo</button></li>
                                        <li><button onClick={handlerSetNivel} title="Contraste aumentado" data-nivel="altocontraste">Contraste aumentado</button></li>
                                        <li><button onClick={handlerSetNivel} title="Monocromático" data-nivel="monocromatico">Monocromático</button></li>
                                        <li><button onClick={handlerSetNivel} title="Escala de cinza invertida" data-nivel="cinzainvertida">Escala de cinza invertida</button></li>
                                        <li><button onClick={handlerSetNivel} title="Cor invertida" data-nivel="corinvertida">Cor invertida</button></li>
                                        <li><button onClick={handlerSetNivel} title="Cores originais" data-nivel="normal">Cores originais</button></li>
                                    </ul>
                                </div>
                            </li>
                            <li role="menuitem">
                                <a href="/mapa-do-site" title="Acessar a página de mapa do site" className="pgmapadosite">
                                    <span className="oculto" lang="pt-br">Mapa do site</span>
                                </a>
                            </li>
                            <li role="menuitem" className="lilibras"><a href="http://www.vlibras.gov.br" aria-label="Link para libras : Link externo" target="_blank" rel="noopener noreferrer" title="Link para libras : Link externo" className="pglibras"><span lang="pt-br">Libras</span></a></li>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    </Wrapper>;
}

export default Acessibilidade;