import styled from 'styled-components';

const MenuDotsWrapper = styled.div`
    position: fixed;
    z-index: 1000;
    display: none;
    transition: all .5s ease;
    bottom: 40%;

    a {
        background-color: transparent !important;
    }

    &.com-footer {
        bottom: 390px;
    }
    
    @media (min-width: 992px){
        display: inline-block; 
        left: 50%;
        margin-left: -625px;
    }
    
    .cd-vertical-nav {
        display: inline-block;

        li {
            text-align: left;
            list-style-type: none;
        }

        a {
            display: inline-block;
            color: #333;
            min-width: 40px;

            span {
                display: inline-block;
            }

            &:hover .cd-label {
                opacity: 1;
                display: inline;
            }

            &.is-selected .cd-dot {
                background-color: black;
            }
        } 

        .cd-dot {
            position: relative;
            height: 7px;
            width: 7px;
            border-radius: 100%;
            background-color: #ccc;
            -webkit-transition: -webkit-transform 0.2s;
            -moz-transition: -moz-transform 0.2s;
            transition: transform 0.2s;
        }

        .cd-dot-line {
            position: absolute;
            width: 10px;
            height: 3px;
            border-radius: 25px;
            margin: 10px 0 0 -5px;            
            background-color: #000;
            color: #000;
            opacity: 0;
        }

        .cd-label {
            position: relative;
            margin-left: -7px;
            -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
            -moz-transition: -moz-transform 0.2s, opacity 0.2s;
            transition: all 0.5s;
            opacity: 0;
            display: none;
            text-transform: uppercase;
            text-decoration: none;
        }

        a:hover {
            .cd-dot {
                background-color: #000;
                height: 14px;
                width: 14px;
                color: #000
            };            

            .cd-label {
                margin-left: 15px;
                opacity: 1;
                color: #000; 
                text-decoration: none;               
            }

            .cd-dot-line {
                opacity: 1;
            }
        }

        li.active {
            .cd-dot, li:hover {
                height: 14px;
                width: 14px;
                margin-left: -3px;
                background-color: #000;
            }

            .cd-dot-line {
                opacity: 1;
            }
        }
    }

    .ligth li.active {

        .cd-label {
            color: #fff;
        }

        .cd-dot, li:hover, .cd-dot-line {
            background-color: #FFF;
        }
    }

    .cd-vertical-nav .ligth a:hover {
        color: #fff;
        
        .cd-label {
            color: #fff;
        }

        .cd-dot, li:hover, .cd-dot-line {
            background-color: #FFF;
        }
    }
`;

export default MenuDotsWrapper;