export const phoneMask = (v) => {
  // se não for numero o campo apaga o digito
  var r = v.replace(/\D/g, "");
  r = r.replace(/[A-Za-z\s]/, "");
  r = r.replace(/^0/, "");
  if (r.length > 10) {
    // formatação para 9 dígitos
    r = r.replace(/^(\d\d)(\d{1})(\d{4})(\d{4}).*/, "($1) $2 $3-$4");
  }
  else if (r.length > 6) {
    // formatação para 8 dígitos
    r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
  }
  else if (r.length > 2) {
    // formatação inicial DDD +4 dígitos
    r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
  }
  else {
    // formatação inicial DDD 
    r = r.replace(/^(\d)/, "($1");
  }
  return r;
};

export function phoneValidation(number) {
  number = number.replace(/[^\d]+/g, '');

  // Elimina numbers invalidos conhecidos	
  if (
    (number.length !== 0 && number.length < 10) ||
    number === "0000000000" ||
    number === "00000000000" ||
    number === "1111111111" ||
    number === "11111111111" ||
    number === "2222222222" ||
    number === "22222222222" ||
    number === "3333333333" ||
    number === "33333333333" ||
    number === "4444444444" ||
    number === "44444444444" ||
    number === "5555555555" ||
    number === "55555555555" ||
    number === "6666666666" ||
    number === "66666666666" ||
    number === "7777777777" ||
    number === "77777777777" ||
    number === "8888888888" ||
    number === "88888888888" ||
    number === "9999999999" ||
    number === "99999999999"
  ) {
    return false;
  }
  return true;
}