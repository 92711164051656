import { api } from 'utils';
import { axiosConfig } from 'config';

export const getWPJSON = async () => {
    return await api.get('/wp/v2');
}

export const getMenu = async () => {
    return await api.get(`${axiosConfig.apiUrl}/Content/GetContentMenu`);
}

export const getPosts = async (params) => {
    return await api.get(`${axiosConfig.apiUrl}/Content/GetContent`, { params });
}

export const getPostBySlug = async slug => {
    return await api.get(`${axiosConfig.apiUrl}/Post/GetPostBySlug?slug=${slug}`);
}

export const getEvents = async (month, year) => {
    return await api.get(`${axiosConfig.apiUrl}/Content/GetEvents?month=${month}&year=${year}`);
}

export const getEventsByDate = async (date) => {
    return await api.get(`${axiosConfig.apiUrl}/Content/GetEventsByDate?dateEvent=${date}`);
}

export const getCategories = async params => {
    return await api.get(`/wp/v2/categories`, { params });
}

export const getTags = async () => {
    return await api.get(`${axiosConfig.apiUrl}/Content/GetTags`);
}

export const getPages = async params => {
    return await api.get(`/wp/v2/pages`, { params });
}

export const getPostsRelated = async (exclude, tags, category, brandSlug, numberOfRel, brandsOnly) => {
    return await api.get(`${axiosConfig.apiUrl}/Content/GetRelated?exclude=${exclude}&tags=${tags}&category=${category}&brandSlug=${brandSlug}l&numberOfRelated=${numberOfRel}&brandsOnly=${brandsOnly}`);
}