import 'default-passive-events';

import { axiosConfig } from 'config';
import React, { Suspense, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from 'routes';
import { Acessibilidade, Alert, Footer, Header, Loader, Cookies } from './components';

import { AuthProvider } from 'contexts/AuthContext';

const App = props => {
  const [pos, setPos] = useState(0);
  const [loaderVisibility, setLoaderVisibility] = useState(props.loading);

  useEffect(() => {
    document.addEventListener('scroll', () => {
      setPos(window.pageYOffset);
    }, { passive: true });

    setLoaderVisibility(props.loading);
  }, [props])

  return <main id="app" className='app'>
    <link rel='stylesheet' id='elementor-icons-css'
      href={`${axiosConfig.contentUrl}/plugins/elementor/assets/lib/eicons/css/elementor-icons.min.css?ver=5.9.1`}
      media='all' />
    <link rel='stylesheet' id='elementor-common-css'
      href={`${axiosConfig.contentUrl}/plugins/elementor/assets/css/common.min.css?ver=3.0.3`}
      media='all' />
    <link rel='stylesheet' id='elementor-animations-css'
      href={`${axiosConfig.contentUrl}/plugins/elementor/assets/lib/animations/animations.min.css?ver=3.0.3`}
      media='all' />
    <link rel='stylesheet' id='elementor-frontend-legacy-css'
      href={`${axiosConfig.contentUrl}/plugins/elementor/assets/css/frontend-legacy.min.css?ver=3.0.3`}
      media='all' />
    <link rel='stylesheet' id='elementor-frontend-css'
      href={`${axiosConfig.contentUrl}/plugins/elementor/assets/css/frontend.min.css?ver=3.0.3`}
      media='all' />
    <link rel='stylesheet' id='elementor-global-css'
      href={`${axiosConfig.contentUrl}/uploads/elementor/css/global.css?ver=1598621220`}
      media='all' />
    <Suspense fallback={<Loader visible={true} />}>
      <Loader visible={loaderVisibility} />
      <Alert {...props.alert} />
      <AuthProvider>
        <Router>
          <Acessibilidade />
          <Header pos={pos}>
          </Header>
          <div className="routes-container" id="conteudo">
            <Routes />
          </div>
          <Footer />
          <Cookies />
        </Router>
      </AuthProvider>
    </Suspense>

  </main >
}

const mapStateToProps = (state) => {
  return {
    loading: state.Loading.visible,
    alert: state.Alert,
  }
}

export default connect(mapStateToProps)(App);
