import styled from 'styled-components';

export const ConsultantWrapper = styled.a`
    &:before {
        display: none;
    }
`;

export const IconConsultant = styled.img`
    width: 20px;
    @media (max-width: 1199px) {
        display: none;
    }
`;

export const IconConsultantResponsive = styled.img`
    width: 32px;
    @media (min-width: 1200px) {
        display: none;
    }
`;