import styled from "styled-components";

export const Controllers = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  transform: scale(1);
  -webkit-transition: all 0.3s cubic-bezier(1, 0.25, 0, 0.75) 0s;
  transition: all 0.3s cubic-bezier(1, 0.25, 0, 0.75) 0s;

  &:hover {
    transform: scale(1.1);
  }
`;

export const Left = styled.img`
  width: 12px;
  height: 12px;
  background: #ffffff;
  box-shadow: 0 2px 4px 0 #00000016;
  border-radius: 50%;
  padding: 3px;
  object-fit: contain;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
`;

export const Right = styled.img`
  width: 12px;
  height: 12px;
  background: #ffffff;
  box-shadow: 0 2px 4px 0 #00000016;
  border-radius: 50%;
  padding: 3px;
  object-fit: contain;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  .rec .rec-carousel-item {
    outline-offset: 0;
    outline-color: transparent;
    outline: none;
  }

  .rec,
  .rec-carousel-item {
    outline-offset: 0;
    outline-color: transparent;
    outline: none;
  }

  .disable {
    @media (max-width: 576px) {
      display: none;
    }
  }

  a {
    opacity: 0.6;
    font-family: TitilliumWeb-Regular;
    font-size: 20px;
    color: #212121;
    letter-spacing: 0;
    text-decoration: none;
    display: inline-block;

    &.active {
      opacity: 1;
      font-family: TitilliumWeb-Bold;
    }

    @media (min-width: 576px) {
      font-size: 14px;
    }
  }

  @media (min-width: 768px) {
    a {
      + a {
        margin-left: 20px;
      }
    }
  }

  @media (min-width: 1200px) {
    a {
      + a {
        margin-left: 40px;
      }
    }
  }
`;
