import React from 'react';

import iconMaps from './assets/menu-dark.png';
import iconMapsWhite from './assets/menu-white.svg';
import { Icon, IconResponsive, MapsWrapper } from './menu-maps.styled.js';

const Maps = props => {
    const lightTheme = props.lightTheme;
    let imgSrc;

    function checkStatus() {
        if (lightTheme.includes('no-scrolled') && lightTheme.includes('white_header')) {
            imgSrc = iconMapsWhite;
            return;
        }
        imgSrc = iconMaps;
    }

    checkStatus();

    return <MapsWrapper href='/mapa-do-site'>
        <Icon src={imgSrc} alt='Mapa' />
        <IconResponsive src={iconMaps} alt="" />
    </MapsWrapper>
}

export default Maps;