import styled from 'styled-components';

export const Wrapper = styled.div`
    background-color: rgba(255, 255, 255, .3);
    z-index: 30;
    position: relative;
    /* margin-top: -12px; */
    
    .menu-initial-position {
        margin-top: 54px;
        position: relative;
    }

    .menu-itens {
        display: flex;
        padding: 20px;
    }

    .children-container {
        margin: auto;
        align-items: center;
        text-align: center;
        padding: 9px;
        position: relative;
    }

    @media (min-width: 768px) {
        .children-container {
            display: flex;
            justify-content: space-between;
            padding: 0;
        }
    }
`;